@import "../../../styles/variables.scss";

.radio-container {
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
	padding-left: 2rem;
	padding-right: 0.5rem;
	height: 1.5rem;
	width: fit-content;
	font-weight: 500;
	color: black;

	input {
		position: absolute;
		opacity: 0;
		height: 0;
		width: 0;
	}

	.radio {
		position: absolute;
		top: 0;
		left: 0;
		height: 1.5rem;
		width: 1.5rem;
		border: $middle-gray 1px solid;
		border-radius: 100%;
		display: flex;
	}

	.radio:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 0.6rem;
		height: 0.6rem;
		border-radius: 50%;
		background: $light-green;
		display: none;
	}

	input:checked ~ .radio:after {
		display: block;
	}
}
