@import "variables.scss";

.disable-scroll {
	overflow: hidden;
}

h1 {
	text-align: center;
	font-family: Lora;
}
h4 {
	font-size: 1.25rem;
}
label {
	color: $dark-green;
	font-size: 0.75rem;
	font-weight: 600;
	margin-bottom: 0.25rem;
}
textarea {
	resize: vertical;
}

body,
html {
	margin: 0;
	padding: 0;
}

/*---------------REUSABLE---------------*/
.text-justify {
	text-align: justify;
}
.text-small {
	font-size: 0.875rem; //14px
}
.text-smaller {
	font-size: 0.75rem; //12px
}
.text-regular {
	font-size: 1rem;
}
.text-big {
	font-size: 1.5rem; //24px
}
.text-bigger {
	font-size: 2rem; //32px
}
.text-color-black {
	color: black;
}

.text-middle-gray {
	color: $middle-gray !important;
}
.text-dark-green {
	color: $dark-green !important;
}
.text-light-green {
	color: $light-green;
}
.text-secondary-dark-green {
	color: $secondary-dark-green;
}
.text-light {
	color: $light !important;
}
.lh-130 {
	line-height: 130%;
}
.lh-120 {
	line-height: 120%;
}
.lh-22 {
	line-height: 22px;
}
.fw-800 {
	font-weight: 800;
}
.fw-700 {
	font-weight: 700;
}
.fw-600 {
	font-weight: 600;
}
.fw-500 {
	font-weight: 500;
}

.bg-dark-green {
	background-color: $dark-green !important;
}

.btn {
	border: none;
	box-shadow: none;
	font-size: 0.875rem;
	height: 3rem;
	font-weight: 600;

	&:focus {
		box-shadow: none;
	}

	&.btn-primary {
		background-color: $primary-tan;
		color: $dark-green !important;

		// TODO: Replace bs variable
		&:hover {
			background-color: $primary-tan-hover;
		}
		&:disabled {
			background-color: $primary-tan-disabled;
		}
		&:active {
			background-color: $primary-tan-active;
		}

		&.btn-disabled-gray:disabled {
			background-color: $light-gray;
			color: #fff !important;
		}
	}

	&.btn-light {
		color: $dark-green !important;
		background-color: $light; // TODO: Replace bs variable

		&:hover {
			background-color: $light-hover;
		}
		&:active {
			background-color: $light-active;
		}
	}
}

.rotate-180 {
	transform: rotate(180deg);
}

.input-base {
	border: 1px solid $light-gray;
	border-radius: 5px;
	color: $middle-gray;
	font-weight: 400;
	font-size: 0.875rem;
	height: $input-height;
	padding: 0.375rem 0.75rem;
	display: flex;
	align-items: center;
}
