@import "../../../styles/variables";

.code-input-char {
	border: none;
	border: 0.1rem solid transparent;
	border-bottom-color: $light-gray;
	background: none;
	color: $light-green;
	margin: 0 0.5rem;
	font-size: 1.5rem;
	outline: none;
	transition: 0.1s ease;
}

.code-input-char-selected {
	border: rgba($light-green, 0.3) 0.1rem solid;
	border-radius: 0.3rem;
}
