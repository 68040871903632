.location-icon {
	height: 1.125rem;
}

.about-us-desc {
	color: #283837;
}

.read-more-text {
	color: #91a09f;
}

.read-more-icon {
	width: 1.25rem;
	padding: 0px;
	transition: transform 1s;
}

.read-less-icon {
	transform: rotate(180deg);
}

.more-button {
	width: 1.5rem;
}

.less-button {
	width: 1.5rem;
	transform: rotate(180deg);
}

.divider {
	border: 1px solid #e6e6e6;
}

.image-counter-wrapper {
	z-index: 1;
	background: rgba(0, 0, 0, 0.5);
	border-radius: 6px;
	bottom: 1.7rem;
}

.hotel-images-slider {
	img {
		height: 75vw; // creates 4:3 aspect ratio
		object-fit: cover;
		background-color: #f3f3f3;
	}
}

.image-counter-numbers {
	letter-spacing: 0.1em;

	color: #ffffff;
}

.hotel-back-button {
	z-index: 1;
	top: 0.85rem;
	left: 0.85rem;
}

.hotel-share-button {
	z-index: 1;

	bottom: 1.6rem;
	right: 4rem;
}

.hotel-share-button-icon {
	z-index: 1;
	bottom: 1.95rem;
	right: 4.45rem;
}

.hotel-favor-button {
	z-index: 1;

	bottom: 1.6rem;
	right: 0.85rem;
}

.hotel-favor-icon {
	z-index: 1;
	bottom: 1.9rem;
	right: 1.25rem;
}
.hotel-full-hart-icon {
	z-index: 1;
	width: 5.3%;
	bottom: 1.85rem;
	right: 1.17rem;
}

.icons-container {
	max-width: 25.8%;
	display: flex;
	justify-content: flex-end;
}

.check-availability-container {
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 1rem;

	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12);
	background: #ffffff;
}

.experience-image-container {
	height: 16rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1rem;
	overflow: hidden; /* Hide any overflowing content */
}

.availability-modal {
	background-color: #fff;
	height: fit-content;
	width: 100%;
	position: fixed;
	bottom: 0;
	opacity: 0;
	transform: translateY(100%);
	box-shadow: 0px -4px 10px rgba(0, 0, 0, 0.3);

	transition-property: transform, opacity;
	transition-duration: 0.3s;
	z-index: 3;
}

.availability-modal-open {
	z-index: 3;
	opacity: 1;
	transform: translateY(0%);
}

.gap-08 {
	gap: 0.8rem;
}

.modal-background {
	background-color: rgba(0, 0, 0, 0.281);
	position: fixed;
	backdrop-filter: blur(2px);
	height: 100vh;
	width: 100vw;
	top: 0;
	left: 0;
	z-index: 1;
}

.page-open-modal {
	height: 100vh;
	overflow-y: hidden;
}
