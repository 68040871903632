.home-page-container {
	padding: 2rem 2rem 5rem;
	.home-page-logo {
		left: 0;
		height: 2.18rem;
	}

	.home-page-title {
		margin: 0;
		font-style: normal;
		font-weight: 700;
		font-size: 1.5rem;
		line-height: 2rem;
		letter-spacing: -0.04em;
		color: #d9d9d9;
	}

	.home-page-cards-container {
		padding: 2rem 0;
		gap: 2rem;

		.home-page-card-container {
			height: 26.5rem;
			padding: 0.9rem;
			border-radius: 1rem;
			color: #fdfdfd;
			background-size: cover !important;
			background-position-x: center !important;

			.home-page-favor-button {
				height: 1.5rem;
			}
			.home-page-un-favor-button {
				height: 1.7rem;
			}

			.home-page-card-description {
				font-family: "Raleway";
				font-style: normal;
				font-weight: 500;
				font-size: 1rem;
				line-height: 1.18rem;
				gap: 0.2rem;

				.home-page-hotel-title {
					font-weight: 700;
				}
			}
		}
	}
}
