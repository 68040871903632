@import "../../../styles/variables";

.input-container {
	position: relative;

	&.input-dark-bg {
		.input {
			color: $light-green !important;
			border-color: $light-green;

			&::placeholder {
				color: $light-green;
			}
		}

		.input-icon {
			background-color: $light-green;
		}

		label {
			color: $light-green;
		}
	}
}

.input {
	color: $dark-green !important;
	font-weight: 400;
	font-size: 0.875rem;
	height: 2.5rem;

	&::placeholder {
		color: $middle-gray;
	}

	&.with-icon-left {
		padding-left: 2.5rem;
	}
	&.with-icon-right {
		padding-right: 2.5rem;
	}

	&.rounded-pill {
		padding: 1rem;

		&.with-icon-left {
			padding-left: 2.6rem;
		}
		&.with-icon-right {
			padding-right: 2.8rem;
		}
	}

	&:focus {
		border-color: $light-green;

		box-shadow: none;
	}
}

.input-icon {
	background-color: $middle-gray;
	position: absolute;
	top: 50%;
	width: 1.4rem;
	height: 1.4rem;
	transition: 0.15s ease-in-out;
	mask-position: center;

	&.left {
		transform: translate(-50%, -50%);
		left: 1.3rem;

		&.rounded {
			left: 1.6rem;
		}
	}
	&.right {
		transform: translate(50%, -50%);
		right: 1.3rem;

		&.rounded {
			right: 1.8rem;
		}
	}

	&.focused {
		background-color: $light-green;
	}
}

.show-password-button {
	right: 0;
	transform: translate(-50%, -50%);

	&.rounded {
		right: 0.3rem;
	}

	&.password-visible {
		mask-image: url("../../../../public/images/icons/eye-crossed.svg");
	}

	&:not(.password-visible) {
		mask-image: url("../../../../public/images/icons/eye.svg");
	}
}
