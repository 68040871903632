@import "variables.scss";

:root {
	--bs-body-font-family: "Manrope";
	--bs-light: rgb(255, 0, 0);
	--bs-light-rgb: rgb(255, 0, 0);
	--bs-form-control-bg: transparent;
	--bs-border-color: #e6e6e6;
	--bs-border-width: 0.1rem;
	--bs-text-primary: red;
	--bs-primary-rgb: red;
}

// TODO: Remove color setting from below, replace --bs variable
.text-light {
	color: $light !important;
}
.text-primary {
	color: $primary-tan !important;
}
.text-secondary {
	color: $middle-gray !important;
}
