@import "../../styles/variables.scss";

.experiences-container {
	display: flex;
	overflow-x: auto;
	flex-wrap: nowrap;
	gap: 1rem;

	&::-webkit-scrollbar {
		display: none;
	}

	& > div {
		width: 13rem;
		min-width: 13rem;
	}
}

.experience-image-placeholder {
	border: 1px solid $light-gray;
	height: 16rem;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 0.7rem;
	overflow: hidden; /* Hide any overflowing content */
}

.experience-image {
	width: 100%; /* Set the image width to fill the container */
	height: auto; /* Automatically adjust the height to maintain the aspect ratio */
	object-fit: cover;
}

.check-in-time-container {
	margin-bottom: 0.7rem;

	.input {
		width: 5.5rem;
		text-align: center;
	}
}

.separator-line {
	height: 1px;
	width: 100%;
	background-color: $light-gray;
}

.add-media-container {
	border: 1px solid $middle-gray;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 2rem 3rem;
}

.btn.add-media-btn {
	font-weight: 500 !important;
	text-align: center !important;
}

.listing-upload-preview-images {
	overflow-x: scroll;
	gap: 0.5rem;
	margin: 0 0 0.5rem;
	padding-bottom: 6px;

	// make scrollbar always visible to make it clear that this is scrollable
	&::-webkit-scrollbar {
		height: 3px;
	}
	&::-webkit-scrollbar-thumb {
		border-radius: 2px;
		background-color: rgba(0, 0, 0, 0.25);
		-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
	}

	img {
		width: 6rem;
		height: 6rem;
		border-radius: 4px;
		object-fit: cover;
	}
}

.admin-create-listing-page {
	label {
		font-size: 0.9rem;
	}
}

.admin-create-listing-loading-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
}

.x-icon {
	position: absolute;
	height: 30px !important;
	width: 30px !important;
	top: -10px;
	right: -10px;
	z-index: 1;
}
.x-icon-xp {
	position: absolute;
	height: 30px !important;
	width: 30px !important;
	top: 10px;
	right: 10px;
	z-index: 1;
}
.location-image {
	width: 100%;
	height: 100%;
	border-radius: 0.75rem;
	object-fit: contain;
}
