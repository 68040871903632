.admin-explore-page-container {
	background-color: #f5f5f5;
	.admin-explore-header-container {
		background: #ffffff;
		box-shadow: 0px 4px 12px -12px rgba(0, 0, 0, 0.04);
		color: #9fa7ad;
	}
	.admin-explore-header-container img {
		height: 2rem;
		width: 2rem;
	}
	.admin-explore-filters {
		background: #ffffff;
		box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
		border-radius: 6px;
	}
	.admin-explore-hotels-container {
		background: #ffffff;
		box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
		border-radius: 8px;

		color: #000000;

		.admin-explore-header {
			box-shadow: 0px 4px 12px -12px rgba(0, 0, 0, 0.336);

			.admin-explore-header-status {
				min-width: 5rem;
			}
		}

		.admin-explore-options-container {
			max-height: 30rem;
			overflow-y: auto;

			.admin-explore-hotels-status {
				min-width: 7.5rem;
			}
			.admin-management-listing-image {
				width: 4.5rem;
				height: 4.5rem;
				border-radius: 4px;
			}
		}
	}
}

.admin-explore-dropdown-menu {
	padding: 0.6rem;
	background: #f5f5f5;
	box-shadow: 0px 4px 12px rgba(249, 208, 153, 0.1);
	border-radius: 8px;

	position: absolute;
	opacity: 0;
	right: 0;
	display: none;

	transition-property: opacity, display;
	transition-duration: 2s;
	z-index: -2;
}

.admin-explore-dropdown-menu-open {
	display: block;
	opacity: 1;
	z-index: 2;
}
