@import "../../../styles/variables.scss";

.checkbox-container {
	display: flex;
	align-items: center;
	position: relative;
	cursor: pointer;
	padding-left: 2rem;
	padding-right: 0.5rem;
	height: 1.5rem;
	width: fit-content;
	font-weight: 500;
	color: black;

	input {
		position: absolute;
		opacity: 0;
		height: 0;
		width: 0;
	}

	.checkmark-box {
		position: absolute;
		top: 0;
		left: 0;
		height: 1.5rem;
		width: 1.5rem;
		border: $middle-gray 1px solid;
		border-radius: 2px;
		display: flex;
	}

	.checkmark {
		position: absolute;
		display: none;
		left: 50%;
		top: 49%;
		transform: translate(-50%, -50%);
	}

	input:checked ~ .checkmark-box .checkmark {
		display: block;
	}
}
