.bottom-navbar-container {
	position: relative;
	padding-bottom: 0.5rem;
	font-size: 3rem;
	position: fixed;
	bottom: 0;
	color: #ecefef;
	gap: 1.5rem;

	font-weight: 700;
	font-size: 0.75rem;
	line-height: 120%;

	.nav-bar-background {
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		background: #425c5a;
		box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.1);
		z-index: -1;
	}

	.nav-buttons {
		min-width: 4.5rem;
		z-index: 5;
		text-decoration: none;
	}

	.bottom-navbar-icons {
		height: 2.4rem;
		transition: transform 0.4s;
	}

	.nav-dropdown-menu {
		box-shadow: 0px 4px 12px rgba(66, 92, 90, 0.5);
		padding: 1rem;
		background-color: #f6f9f8;
		border-radius: 8px;

		font-weight: 700;
		font-size: 0.85rem;
		line-height: 120%;
		/* or 14px */

		color: #425c5a;

		position: absolute;
		top: 0;
		right: 0.8rem;
		opacity: 0;
		display: none;

		transition-property: transform, opacity, display;
		transition-duration: 0.4s;
		z-index: -2;

		.nav-link-text {
			text-decoration: none;
			color: inherit;
		}
	}

	.nav-dropdown-select {
		transition: color 0.5s;
	}

	.nav-dropdown-select-clicked {
		color: #ffcea2;
	}

	.nav-dropdown-menu-clicked {
		transform: rotate(-90deg) !important;
	}

	.nav-dropdown-menu-open {
		display: block;
		opacity: 1;
		transform: translateY(-110%);
	}
}
