$light: #ecefef;
$light-hover: #d5dada;
$light-active: #c5c9c9;

$primary-tan: #ffcea2;
$primary-tan-disabled: rgba(255, 206, 162, 0.75);
$primary-tan-hover: #dab08b;
$primary-tan-active: #c59d7a;

$secondary-dark-green: #637573;
$dark-green: #425c5a;
$light-green: #a2bfbd;

$middle-gray: #a1a1a1;
$light-gray: #e6e6e6;
$lightest-gray: #f5f5f5;

$input-height: 2.5rem;
