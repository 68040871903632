@import "../../styles/variables.scss";

.registration-modal {
	position: fixed;
	z-index: 5;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.21);
	backdrop-filter: blur(2px);
}

.registration-modal-container {
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.406);
	height: 85%;
	width: 85%;
	overflow-y: auto;
	background-color: $dark-green;
	padding: 20px;
	border-radius: 10px;
}
