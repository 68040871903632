.add-favorites-text {
	margin-top: 85%;
	font-weight: 700;
	font-size: 1.2rem;
	line-height: 1.375rem;

	color: #a1a1a1;
}

.favorites-login-container {
	height: 100vh;
	width: 100%;
	z-index: -1;
	padding-bottom: 4rem;
	position: absolute;
	top: 0;
	left: 0;
}
